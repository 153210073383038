window.scrollTo(0, 0);

const sidebarEl = document.getElementById('sidebar-wrapper');
const openBtnEl = document.getElementById('menu-open-link');
const closeBtnEl = document.getElementById('menu-close-link');

const scrollTo = (selector) => {
  document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
};

const toggleMenu = () => {
  sidebarEl.classList.toggle('active');
};

const isMenuOpen = () => sidebarEl.classList.contains('active');

const handleClick = (event) => {
  const isOpenBtnClick = openBtnEl.contains(event.target);
  const isCloseBtnClick = closeBtnEl.contains(event.target);

  // Open and close side menu
  if (isOpenBtnClick || isCloseBtnClick) {
    event.preventDefault();
    toggleMenu();
    return;
  }

  const isSidebarClick = sidebarEl.contains(event.target);

  // Close side-menu on outside click when open
  if (!isSidebarClick && !isOpenBtnClick && isMenuOpen()) {
    toggleMenu();
    return;
  }

  let targetId = null;
  let currentNode = event.target;

  while (currentNode && currentNode.getAttribute) {
    targetId = currentNode.getAttribute('data-target');
    if (targetId) break;
    currentNode = currentNode.parentNode;
  }

  if (targetId) {
    event.preventDefault();
    scrollTo(`#${targetId}`);

    if (isMenuOpen()) {
      toggleMenu();
    }
  }
};

document.addEventListener('click', handleClick);
